<template>
    <PageHeader wide>
        <h1 v-if="manufacturer">
            <a :href="manufacturer.WebsiteUrl" :data-mfr="manufacturerNameId" data-ga="m" target="_blank">
                {{ manufacturer.DisplayName }}
            </a>
        </h1>
    </PageHeader>
</template>
<script setup lang="ts">
const manufacturer = useStateManufacturer();
const manufacturerNameId = computed(() => `${manufacturer.value?.Name} [${manufacturer.value?.ManufacturerId}]`);
</script>
