<template>
    <div v-if="hasProductSeries" class="flex flex-col gap-4">
        <h2>{{ $t("ProductSeries.PopularProductSeries") }}</h2>
        <GridItems :items="props.series">
            <template #item="{ item }">
                <div class="flex-1 font-bold">
                    <NuxtLink :to="item.ProductSeriesUrl">{{ item.BasePartNumber }}</NuxtLink>
                </div>
                <div>{{ item.MasterCategoryName }}</div>
                <div>{{ formatTotalProducts(item.TotalProducts) }}</div>
            </template>
        </GridItems>
        <div>
            <NuxtLink :to="allSeriesLink">{{ allSeriesLabel }}</NuxtLink>
        </div>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();
const manufacturer = useStateManufacturer();
const localePath = useLocalePath();

const props = defineProps<{ series: ProductSeries[] | null }>();

const hasProductSeries = computed(() => Array.isArray(props.series) && props.series.length > 0);

const allSeriesLink = computed(() => localePath("/manufacturers/" + manufacturer.value?.VirtualPath + "/series"));
const allSeriesLabel = computed(() => t("ProductSeries.SeeAllSeriesFor", [manufacturer.value?.Name]));

function formatTotalProducts(no?: number): string {
    return t("ProductSeries.TotalProducts", [formatNumber(no)]);
}
</script>
