<template>
    <div v-if="hasAnyParts" class="flex flex-col gap-4">
        <h2>{{ $t("TopSearches.TopSearchesHeader") }}</h2>

        <div v-if="props.parts" class="grid lg:grid-cols-2 gap-8">
            <div v-if="hasTopParts">
                <div
                    v-for="topPart in props.parts.TopParts"
                    :key="topPart.PartNumber"
                    class="flex gap-2 xl:gap-4 items-center">
                    <div class="shrink-0">
                        <PartImage size="lg" :part="topPart" />
                    </div>
                    <div>
                        <a
                            :href="topPart.PartUrl"
                            :title="topPart.PartNumber"
                            @click="logPopularPartClick(topPart.ManufacturerName)">
                            {{ topPart.PartNumber }}
                        </a>
                        <p>{{ topPart.Description }}</p>
                    </div>
                </div>
            </div>
            <div v-if="hasBottomParts" class="">
                <a
                    v-for="bottomPart in props.parts.BottomParts"
                    :key="bottomPart.PartNumber"
                    class="block mb-2 text-sm"
                    :href="bottomPart.PartUrl"
                    :title="bottomPart.PartNumber"
                    @click="logPopularPartClick(bottomPart.ManufacturerName)">
                    {{ bottomPart.PartNumber }}
                </a>
            </div>
        </div>
        <div
            class="flex flex-col gap-4"
            v-html="$t('Manufacturer.SearchPromotionHtml', [manufacturer?.DisplayName])"></div>
    </div>
</template>
<script setup lang="ts">
const { gtag } = useGtag();
const manufacturer = useStateManufacturer();

const props = defineProps<{ parts: ManufacturerPopularParts | null }>();

const hasTopParts = computed(() => props.parts && props.parts.TopParts.length > 0);
const hasBottomParts = computed(() => props.parts && props.parts.BottomParts.length > 0);
const hasAnyParts = computed(() => hasTopParts.value || hasBottomParts.value);

function logPopularPartClick(manufacturerName: string) {
    gtag("event", "popular_part_click", { manufacturer_name: manufacturerName });
}
</script>
