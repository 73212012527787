<template>
    <ButtonGroup>
        <SearchAutocomplete
            id="mfrSearchText"
            v-model="searchText"
            :placeholder="placeholder"
            show-min-length-message
            class="relative grow h-10"
            custom-class="h-full w-full rounded-l border-r-0 rounded-r-none"
            custom-container-class="search-dropdown search-menu-list"
            custom-list-class="search-menu-list"
            :results="searchResults"
            @search="onSearch"
            @select="onSelect"
            @enter="executeSearch"
            @clear="onClear" />
        <Button id="mfrSearchButton" :title="$t('Layout.SearchButtonTitle')" @click="onSearchClick">
            <span class="fas fa-magnifying-glass" aria-hidden="true" />
        </Button>
    </ButtonGroup>
</template>
<script setup lang="ts">
const manufacturer = useStateManufacturer();
const searchNavigate = useSearchNavigate();
const { autocomplete } = useAutocomplete("api/manufacturer/autocomplete");
const { t } = useI18n();

const searchResults = ref<AutocompleteItem[]>([]);
const searchText = ref("");

const placeholder = computed(() => t("Layout.ManufacturerDefaultSearchOverlayText", [manufacturer.value?.DisplayName]));

function onClear() {
    searchResults.value = [];
}

async function onSearch(searchTerm: string) {
    searchResults.value = await autocomplete(searchTerm, { virtualPath: manufacturer.value?.VirtualPath });
}

async function onSelect(item: AutocompleteItem) {
    searchText.value = item.label;
    const result = searchResults.value.find((x) => x.label == item.label && x.category == item.category);
    // only manufacturer and part category results will go directly to specific urls, everything else treat as a search.
    if (result && result.url) {
        await navigateTo(result.url, { external: true });
    } else {
        searchNavigate(item.label, manufacturer.value?.VirtualPath);
    }
}

function onSearchClick() {
    searchNavigate(searchText.value, manufacturer.value?.VirtualPath);
}

function executeSearch(searchTerm: string) {
    searchNavigate(searchTerm, manufacturer.value?.VirtualPath);
}
</script>
