<template>
    <div class="flex flex-col gap-4">
        <ManufacturerSearchBox class="max-w-3xl" />
        <div class="max-w-3xl">{{ $t("Manufacturer.SearchHelp") }}</div>
        <template v-if="hasSearches">
            <div class="font-bold max-w-3xl">{{ $t("Manufacturer.SearchExamples") }}</div>
            <GridItems class="grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
                <GridItem
                    v-for="search in searches"
                    :key="search.PartNumber"
                    class="flex-row gap-4 text-sm min-h-fit overflow-clip">
                    <div v-if="search.ImageUrl" class="shrink-0">
                        <PartImage size="md" :part="search" />
                    </div>
                    <div>
                        <NuxtLink external :to="search.PartUrl" class="block mb-2 font-bold break-all">
                            {{ search.PartNumber }}
                        </NuxtLink>
                        <div>{{ search.Description }}</div>
                    </div>
                </GridItem>
            </GridItems>
        </template>
    </div>
</template>
<script setup lang="ts">
const route = useRoute();

const { data: searches } = await useFetchLocaleApi<ManufacturerExampleSearch[]>(`api/manufacturer/example-searches`, {
    query: { virtualPath: route.params.company, limit: 3 },
});

const hasSearches = computed(() => searches.value && searches.value.length > 0);
</script>
