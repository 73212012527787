<template>
    <div v-if="hasRegions" class="flex flex-col gap-4 xl:gap-x-8">
        <h2>{{ $t("Manufacturer.AuthorizedDistributorsHeading") }}</h2>
        <div v-if="hasSponsors" class="-mx-2 flex flex-wrap gap-4 xl:gap-x-8">
            <NuxtLink v-for="sponsor in sponsors" :key="sponsor.Id" :to="distributorPath(sponsor)" class="p-2">
                <EnhancedImage
                    :src="cdnUrl + sponsor.LogoUrl"
                    :alt="sponsor.Name"
                    css-class="w-full h-[40px] max-w-[140px]"
                    height="40"
                    width="140" />
            </NuxtLink>
        </div>
        <div class="flex gap-8 flex-wrap">
            <div v-for="region in regions" :key="region.RegionId" class="pb-4">
                <h3 class="mb-1 pb-1 border-b-2 border-black text-sm">{{ region.RegionName }}</h3>
                <NuxtLink
                    v-for="dist in region.ManufacturerDistributors"
                    :key="dist.DistributorId"
                    :to="distributorPath(dist)"
                    class="block my-2">
                    {{ dist.Name }}
                </NuxtLink>
            </div>
        </div>
    </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const localePath = useLocalePath();

const props = defineProps<{ regions: ManufacturerRegion[] | null; sponsors: ManufacturerSponsor[] | null }>();

const hasRegions = computed(() => Array.isArray(props.regions) && props.regions.length > 0);
const hasSponsors = computed(() => Array.isArray(props.sponsors) && props.sponsors.length > 0);

const distributorPath = (distributor: ManufacturerSponsor | ManufacturerDistributor) =>
    localePath("/distributors/" + distributor.VirtualPath);
</script>
