<template>
    <div v-if="manufacturer">
        <a
            v-if="manufacturer.LogoUrl"
            :href="manufacturer.WebsiteUrl"
            :data-mfr="manufacturerNameId"
            data-ga="m"
            class="block mb-4">
            <EnhancedImage
                :src="cdnUrl + manufacturer.LogoUrl"
                :alt="manufacturer.DisplayName"
                height="96"
                width="240"
                css-class="max-h-24 max-w-60 object-left" />
        </a>
        <h3 v-else>
            <a :href="manufacturer.WebsiteUrl" :data-mfr="manufacturerNameId" data-ga="m">
                {{ manufacturer.DisplayName }}
            </a>
        </h3>
        <div class="h4 my-4">{{ $t("Manufacturer.JumpToSection") }}</div>
        <slot />
        <p class="my-4 text-sm">{{ manufacturer.Description }}</p>
        <div class="h4">{{ $t("Manufacturer.Brands") }}</div>
        <ul>
            <li v-for="item in visibleItems" :key="item.Id" class="my-2">
                <NuxtLink :to="item.VirtualPath">
                    {{ item.Name }}
                </NuxtLink>
            </li>
        </ul>
        <div v-if="!showAll && remainingCount && remainingCount > 0">
            <Button variant="link" @click="showAll = true">
                <span>{{ $t("PartCategory.ShowMoreManufacturers", [remainingCount]) }}</span>
                <i class="fas fa-caret-down"></i>
            </Button>
        </div>
    </div>
</template>
<script setup lang="ts">
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const manufacturer = useStateManufacturer();
const PREVIEW_LIMIT = 8;
const showAll = ref(false);
const route = useRoute();

const { data: related } = await useFetchLocaleApi<Company[]>("api/manufacturer/related", {
    query: { virtualPath: route.params.company },
});

const visibleItems = computed(() => {
    if (!Array.isArray(related.value)) return [];
    return showAll.value ? related.value : related.value.slice(0, PREVIEW_LIMIT);
});

const remainingCount = computed(() => {
    return Array.isArray(related.value) && related.value.length - PREVIEW_LIMIT;
});

const manufacturerNameId = computed(() => `${manufacturer.value?.Name} [${manufacturer.value?.ManufacturerId}]`);
</script>
