<template>
    <div v-if="hasCategories == true" class="flex flex-col gap-4">
        <h2>{{ $t("TopSearches.TopCategoriesHeader") }}</h2>
        <p v-if="hasParametricSearches">
            {{ parametricSearchText }}
            <NuxtLink v-for="search in parametricSearches" :key="search.url" :to="search.url">
                {{ search.label }}
            </NuxtLink>
        </p>
        <GridItems :items="props.categories?.Categories">
            <template #item="{ item: cat }">
                <div class="flex-1 font-bold">
                    <NuxtLink :to="cat.CategoryUrl">{{ cat.Name }}</NuxtLink>
                </div>
                <div>{{ formatPartsInStock(cat.PartsInStock) }}</div>
                <div>{{ formatTotalParts(cat.TotalParts) }}</div>
            </template>
        </GridItems>
    </div>
</template>
<script setup lang="ts">
const { t } = useI18n();

const props = defineProps<{ categories: ManufacturerCategories | null }>();

const parametricSearchText = computed(() => {
    return t("Manufacturer.CategoryParametricSearch");
});

const parametricSearches = computed(() => {
    return props.categories?.ParametricSearches.map((search) => {
        const [filter] = search.Filters;
        const queryParams = [filter.PartSpecificationId, filter.Value].map(encodeURIComponent).join("=");
        return {
            url: [search.CategoryUrl, queryParams].join("?"),
            label: [filter.Value, search.CategoryName].join(" "),
        };
    });
});

const hasCategories = computed(
    () => Array.isArray(props.categories?.Categories) && props.categories.Categories.length > 0
);

const hasParametricSearches = computed(() => Array.isArray(props.categories?.ParametricSearches));

function formatPartsInStock(no?: number): string {
    return t("Global.PartsInStock", [formatNumber(no)]);
}

function formatTotalParts(no?: number): string {
    return t("ProductSeries.TotalProducts", [formatNumber(no)]);
}
</script>
