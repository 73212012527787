<template>
    <section v-if="manufacturer" class="text-base">
        <ManufacturerHeader id="mfr-header" />
        <div class="container-fluid flex flex-col sm:flex-row gap-4 sm:gap-8 pt-8">
            <div class="shrink-0 sm:w-64 md:w-72 sm:order-last xl:w-80">
                <ManufacturerDetail id="mfr-detail" class="sm:sticky top-0 md:top-20">
                    <ul>
                        <li
                            v-for="section in sections"
                            :key="section.id"
                            class="mb-1.5"
                            :class="{ 'font-bold': section.id == activeId }">
                            <a :href="'#' + section.id" @click="setActive(section.id)"> {{ section.label }} </a>
                        </li>
                    </ul>
                </ManufacturerDetail>
            </div>
            <div class="grow flex flex-col gap-2">
                <ManufacturerSearch id="mfr-search" />
                <ManufacturerDistributors
                    id="mfr-distributors"
                    class="sm:scroll-mt-16"
                    :regions="regionsData"
                    :sponsors="sponsorsData" />
                <ManufacturerCategories id="mfr-categories" class="sm:scroll-mt-16" :categories="categoriesData" />
                <ManufacturerSeries id="mfr-series" class="sm:scroll-mt-16" :series="seriesData" />
                <ManufacturerProducts id="mfr-products" class="sm:scroll-mt-16" :parts="partsData" />
            </div>
        </div>
    </section>
</template>
<script setup lang="ts">
import { useActiveScroll } from "vue-use-active-scroll";

const route = useRoute();
const { t } = useI18n();
const manufacturer = useStateManufacturer();
const localePath = useLangPath();

await useFetchLocaleApi<ManufacturerDetails>("api/manufacturer", {
    query: { virtualPath: route.params.company },
    onResponse({ response }) {
        manufacturer.value = response._data;
    },
    onResponseError({ response, error }) {
        manufacturer.value = null;
        throw showError({ statusCode: response.status, statusMessage: error?.message, ...error });
    },
});

useSetCanonicalUrl(localePath("/manufacturers/" + manufacturer.value?.VirtualPath));

const title = computed(() => t("Manufacturer.ManufacturerTitle", [manufacturer.value?.DisplayName]));
const description = computed(() => t("Manufacturer.MetaDescriptionFormat", [manufacturer.value?.DisplayName]));

useSeoMeta({ title, ogTitle: title, description, ogDescription: description });

const fetchOptions = { query: { virtualPath: route.params.company } };
const [
    { data: sponsorsData },
    { data: regionsData },
    { data: categoriesData },
    { data: seriesData },
    { data: partsData },
] = await Promise.all([
    useFetchLocaleApi<ManufacturerSponsor[]>("api/manufacturer/sponsors", fetchOptions),
    useFetchLocaleApi<ManufacturerRegion[]>("api/manufacturer/distributors", fetchOptions),
    useFetchLocaleApi<ManufacturerCategories>("api/manufacturer/categories", fetchOptions),
    useFetchLocaleApi<ProductSeries[]>(`api/product-series/popular`, fetchOptions),
    useFetchLocaleApi<ManufacturerPopularParts>(`api/manufacturer/popular-parts`, fetchOptions),
]);

const hasRegions = computed(() => Array.isArray(regionsData.value) && regionsData.value.length > 0);
const hasCategories = computed(
    () => Array.isArray(categoriesData.value?.Categories) && categoriesData.value.Categories.length > 0
);
const hasSeries = computed(() => Array.isArray(seriesData.value) && seriesData.value.length > 0);
const hasTopParts = computed(() => partsData.value && partsData.value.TopParts.length > 0);
const hasBottomParts = computed(() => partsData.value && partsData.value.BottomParts.length > 0);
const hasAnyParts = computed(() => hasTopParts.value || hasBottomParts.value);

const sections = computed(() => {
    return [
        hasRegions.value ? { id: "mfr-distributors", label: t("Manufacturer.AuthorizedDistributorsHeading") } : null,
        hasCategories.value ? { id: "mfr-categories", label: t("TopSearches.TopCategoriesHeader") } : null,
        hasSeries.value ? { id: "mfr-series", label: t("ProductSeries.PopularProductSeries") } : null,
        hasAnyParts.value ? { id: "mfr-products", label: t("TopSearches.TopSearchesHeader") } : null,
    ].filter((s) => s != null);
});

const sectionIds = computed(() => sections.value.map((s) => s.id));

const { setActive, activeId } = useActiveScroll(sectionIds, {
    jumpToFirst: false,
    edgeOffset: { first: 0, last: 0 },
    overlayHeight: 64,
});
</script>
